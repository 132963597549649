import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Layout from "components/shared/Layout";
import GameStores from "components/market/GameStores";
import GamesRelatedContent from "components/market/GamesRelatedContent";
import CTA from "components/shared/CTA";
import { Link } from "gatsby";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/gaming.svg";

export default () => (
  <>
    <Meta url="/market/game-stores/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Game Store"
      black="Index"
      text={
        <p>
          The AppInChina Game Store Index is the market-leading index of China’s
          largest Android app stores that publish games. We update our index on
          a quarterly basis using the most accurate data sources available.
          <br /> <br />
          If you intend to publish your mobile game in China, think
          strategically: Each game store has its own audience, strengths and
          weaknesses, depending on the nature of your game and the market you
          are targeting. For advice and insight, please{" "}
          <Link to="/get-started/">contact us</Link>.

          <br /> <br />
          Last Updated: May 2022
        </p>
      }
    />
    <br />
    <br />
    <Layout>
      <GamesRelatedContent>
        <GameStores />
      </GamesRelatedContent>
      <br />
      <br />
    </Layout>
    <CTA titleLevel="h3" />
    <Footer titleLevel="h4" />
  </>
);
