import React from "react";
import Table from "components/shared/Table";
import { GAME_STORES } from "src/utils/market/game-stores";
import * as classes from "../rankings.module.less";
import tick from "images/svg_icons/tick.svg";
import cross from "images/svg_icons/criss-cross.svg";

export default () => {
  let columns = [
    {
      hideResponsiveTitle: true,
      title: "App Store",
      dataIndex: "text",
      render: (text, element) => (
        <div className={classes.storeName}>
          <i>{element.index}</i>
          
          <div className={classes.image}>{element.image}</div>
          {element.website ? (
            <a href={element.website} rel="noopener noreferrer" target="_blank">
              <span>{text}</span>
            </a>
          ) : (
            <span>{text}</span>
          )}
        </div>
      ),
    },
    {
      title: "Chinese Name",
      dataIndex: "chinese_name",
      className: classes.right,
    },
    {
      title: "Monthly Active Users (MAU)",
      dataIndex: "mau",
      className: classes.right,
    },
    {
      title: "MAU Change",
      dataIndex: "mau_change",
      className: classes.right,
    },
    {
      title: "Games Only Store",
      dataIndex: "games_only",
      className: classes.right,
      render: (val) => (
        <img
          className={classes.tick}
          src={val ? tick : cross}
          alt={val ? "yes" : "no"}
        />
      ),
    },
  ];

  return (
    <Table
      responsive
      dataSource={GAME_STORES}
      columns={columns}
      rowKey="index"
      expandedRowRender={(record) => (
        <div>
          {record.details.map((item) => (
            <p className={classes.detailsP}>{item}</p>
          ))}
        </div>
      )}
    />
  );
};
