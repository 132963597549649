import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const imgProps = {
  objectPosition: "50% 50%",
  loading: "lazy",
  layout: "fullWidth",
  placeholder: "blurred",
  alt: "Game Store Icon",
};

const raw_game_stores_data = [
  {chinese_name: '华为应用市场',
  details: ['Huawei AppGallery, formerly known as Huawei App Market is the official app distribution platform for Huawei. Huawei is a large company manufacturing telecommunications equipment and consumer electronics.',
  '',
  'The Huawei App Market’s self-developed "Fuxi Algorithm" is used to accurately recommend content to users. Points can be awarded for signing in or downloading apps, used for lottery and gift rewards. It includes features for one-click installation and uninstalling apps, as well as auto-updating features when a device is idle. A game license is required for ALL games on the Huawei App Market.'],
  games_only: false,
  image: <StaticImage src="../../images/game_stores/huawei.svg" {...imgProps} />,
  mau: '421,788,000',
  mau_change: '+0.51%',
  text: 'Huawei AppGallery',
  website: 'https://appgallery.huawei.com/Featured'},
  {chinese_name: 'Oppo软件商店',
  details: ['Oppo is a Chinese consumer electronics and mobile communications company with popular products such as smartphones. It shares a parent company with Vivo (BKK Electronics). Oppo Game Center is its games app that comes pre-installed on Oppo devices.'],
  games_only: false,
  image: <StaticImage src="../../images/game_stores/oppo.png" {...imgProps} />,
  mau: '187,432,000',
  mau_change: '+1.69%',
  text: 'Oppo Software Store',
  website: 'https://store.oppomobile.com/'},
  {chinese_name: 'VIVO应用商店',
  details: ['VIVO is a Chinese consumer electronics company that makes smartphones, accessories, software, and provides online services. BBK Electronics used to be its parent company just like it used to be the parent company of Oppo.',
  'VIVO is the only app store on this list that does not allow completely free games on its platform - however, it is allowed if your free game is ad-supported.'],
  games_only: false,
  image: <StaticImage src="../../images/game_stores/vivo.png" {...imgProps} />,
  mau: '163,938,000',
  mau_change: '+2.97%',
  text: 'VIVO V-Appstore',
  website: 'https://dev.vivo.com.cn/distribute/appStore'},
  {chinese_name: '应用宝',
  details: ['Tencent is the largest internet company in Asia, publisher of the wildly popular WeChat app used ubiquitously across China. Though the company does not produce any mobile phones, its app store has remained the one of the most popular stores in China for many years. Tencent rarely allows pay-to-download games or apps on its platform.'],
  games_only: false,
  image: <StaticImage src="../../images/game_stores/tencent.png" {...imgProps} />,
  mau: '156,006,000',
  mau_change: '-4.81%',
  text: 'Tencent My App',
  website: 'https://webcdn.m.qq.com/webapp_myapp/index.html#/'},
  {chinese_name: '小米应用商店',
  details: ['Xiaomi is a very popular Chinese electronics company with products including smartphones, mobile apps, laptops, and home appliances. MIUI App Store is the default app store installed on its phone across China. Xiaomi’s mobile phones ship with its own Android firmware (MIUI), based on Google’s Android operating system.'],
  games_only: false,
  image: <StaticImage src="../../images/game_stores/miui.png" {...imgProps} />,
  mau: '97,782,000',
  mau_change: '-8.30%',
  text: 'MIUI App Store',
  website: 'https://app.mi.com/'},
  {chinese_name: '百度手机助手',
  details: ['Even before Google left the Chinese market in 2010, Baidu had been the country’s largest search engine. It remains the dominant player to this day, which is why it is often referred to as “The Google of China”. Baidu’s app store, Baidu Mobile Assistant, is always served up as an option when users search for an app on baidu.com. Baidu Mobile Assistant does not allow pay-to-download games on its platform, nor any ad-supported multi-player games.'],
  games_only: false,
  image: <StaticImage src="../../images/game_stores/baidu.jpeg" {...imgProps} />,
  mau: '69,203,000',
  mau_change: '+1.31%',
  text: 'Baidu Mobile Assistant',
  website: 'http://as.baidu.com/'},
  {chinese_name: '360手机助手',
  details: ['360 Mobile Assistant is a Chinese app store from Qihoo 360, a Chinese internet security company with products including antivirus software and a popular secure web browser used by millions across China. 360 Mobile Assistant does not allow pay-to-download games on its platform.'],
  games_only: false,
  image: <StaticImage src="../../images/game_stores/360.png" {...imgProps} />,
  mau: '65,660,000',
  mau_change: '-6.08%',
  text: '360 Mobile Assistant',
  website: 'http://zhushou.360.cn/'},
  {chinese_name: '三星应用商店',
  details: ['Samsung Electronics is a South Korean multi-national manufacturer of smartphones, TVs, semiconductors, and other home electronics. Its flagship series of smartphones is the Galaxy series, and the Samsung App Store comes preinstalled on all Samsung smartphones. In 2019, Samsung announced it would stop manufacturing mobile phones in China due to lack of demand.'],
  games_only: false,
  image: <StaticImage src="../../images/game_stores/samsung_app_store.png" {...imgProps} />,
  mau: '52,592,000',
  mau_change: '-4.44%',
  text: 'Samsung App Store',
  website: 'https://galaxystore.samsung.com/apps'},
  {chinese_name: 'TapTap',
  details: ['TapTap is simply called “TapTap” in Chinese. Developed by Yiwan (Shanghai) Network Technology Co., Ltd., it offers a wide range of content in languages like Chinese, Japanese, and Korean. Users can download the latest releases in their native language without having to search for a file with the desired language. Taptap allows upload of images and videos of gameplay by the TapTap community. There are also chat features between users, with forums and leaderboards for sharing achievements. TapTap is a games-only store. It does not take a cut from ad-supported free games, and takes a very low cut (5%) from ad-supported games, compared to the rest of the stores on this list.'],
  games_only: true,
  image: <StaticImage src="../../images/game_stores/taptap.png" {...imgProps} />,
  mau: '19,290,300',
  mau_change: '-3.11%',
  text: 'TapTap',
  website: 'https://www.taptap.com/'},
  {chinese_name: '4399游戏盒',
  details: ["4399 is a subsidiary of 4399 Network Co., Ltd., a publisher, developer, and operator of Internet leisure and entertainment platforms and games founded in 2013. The company's main business focuses on three major sectors: online platform, game publishing and game development. It currently includes the development and operation of Internet page games, mobile games, online website platforms and mobile platforms. The mobile version of its store includes game recommendations and social interaction such as sharing of game status and achievements. It is a games-only app store, and is available on web and mobile. 4399 also has an English version of its webpage at 4399en.com"],
  games_only: true,
  image: <StaticImage src="../../images/game_stores/4399.png" {...imgProps} />,
  mau: '15,234,200',
  mau_change: '-5.28%',
  text: '4399',
  website: 'http://a.4399.cn/'},
  {chinese_name: '豌豆荚',
  details: ['Wandoujia was founded in 2009 by a former employee of Google China and a telecom engineer. It was China’s 5th largest Android app store when it was purchased by Alibaba in 2016. The company developed a multimedia marketplace as an alternative to streaming large amounts of data that allows users to search for videos across multiple platforms, download them locally, and compress them.'],
  games_only: false,
  image: <StaticImage src="../../images/game_stores/wandoujia.png" {...imgProps} />,
  mau: '13,202,000',
  mau_change: '+7.42%',
  text: 'Wandoujia',
  website: 'https://www.wandoujia.com/apps'},
  {chinese_name: 'PP助手',
  details: ['Alibaba’s app store in China is PP Assistant. In addition to apps for Android, PP Assistant also has two app stores for iOS, including one for iOS devices that have been “jailbroken”.'],
  games_only: false,
  image: <StaticImage src="../../images/game_stores/pp.png" {...imgProps} />,
  mau: '13,068,000',
  mau_change: '-7.48%',
  text: 'PP Assistant',
  website: 'https://www.25pp.com/android/'},
  {chinese_name: '九游',
  details: ['9game is a domestic professional gaming platform with more than 60 million game players. In 2019, Jiuyou APP went through a comprehensive upgrade. It provides services such as game downloads, game news, and game package etc. 9 game has successfully built a community for young gamers by opening its game club, allowing independent UGC video content, and gathering players from different game circles.'],
  games_only: true,
  image: <StaticImage src="../../images/game_stores/9_game.png" {...imgProps} />,
  mau: '10,269,500',
  mau_change: '-2.16%',
  text: '九游',
  website: 'https://www.9game.cn'},
  {chinese_name: 'Steam International',
  details: ['Steam is a video game digital distribution service by Valve, makers of the popular Half Life series of games. It was launched in 2003 as a software client in September 2003 for Valve to provide automatic updates for their games, and has since expanded to publish third-party games. Steam Global is currently widely available in China, but at any time could be blocked in favor of the newer Steam China store. Steam Global does not allow any ad-supported multi-player games on its platform.'],
  games_only: true,
  image: <StaticImage src="../../images/game_stores/steam.jpeg" {...imgProps} />,
  mau: '9,731,700',
  mau_change: '+1.48%',
  text: 'Steam International',
  website: 'https://store.steampowered.com/'},
  {chinese_name: '联想软件商店',
  details: ['Le Store is an Android application store built by Lenovo Group, a Chinese multi-national company, founded in 1984 and headquartered in Beijing. By 2019, it was the world’s largest PC vendor by unit sales, but they also develop storage devices, IT management software, smartphones, tablets and smart TVs running the Android operating system. The Lenovo Le Store is preinstalled on many of its devices. The store allows users to accumulate Le Dou points for completing daily tasks and sign-ins in exchange for various prizes and gift giveaways. It advocates the attitude of " Enjoy apps and live a happy life”. The store is divided into three version: Web, phone, and Pad (tablet). Le Store does not allow pay-to-download games on its platform, and does not take a cut from ad-supported free games.'],
  games_only: false,
  image: <StaticImage src="../../images/game_stores/lenovo.jpeg" {...imgProps} />,
  mau: '8,423,000',
  mau_change: '+2.73%',
  text: 'Lenovo LeStore',
  website: 'https://lestore.lenovo.com/'}
];

export const GAME_STORES = raw_game_stores_data.map((it, index) => ({
  ...it,
  index: index + 1,
}));

export const OTHER_GAME_STORES = [
  {
    title: "Gionee (金立游戏中心)",
    description: `Gionee Game Hall is a mobile game platform under Gionee Mobile, a
          well-known mobile phone brand in China. As the most important part of
          the mobile Internet business and Amigo system ecosystem, it integrates
          the company's resources across the board.
          Game Hall has both a mobile platform and a web version.`,

    img: (
      <StaticImage src="../../images/game_stores/gionee.jpg" {...imgProps} />
    ),
    url: "http://game.gionee.com/Front/Index/index/?intersrc=mobileweb",
  },
  {
    title: "Meizu Flyme (魅族应用商店)",
    description:
      "Meizu Technology Company is a consumer electronics, digital publishing, and retail company established in March 2004 in Zhuhai, China. The company produces a series of mobile phones for the Chinese domestic market and abroad. They have more than 2000 retail stores across the country, and are actively selling their smartphones overseas in Europe, Asia, India, and the UK. Alibaba is an investment partner in the company. The Meizu Flyme store comes preinstalled on all of Meizu’s mobile smartphones.",
    img: <StaticImage src="../../images/game_stores/meizu.png" {...imgProps} />,
    url: "http://app.meizu.com/games/public/index",
  },
  {
    title: "3839 (好游快爆)",
    description:
      "A store by Xiamen Chunyou Interactive Technology Co., Ltd. In addition to the normal features of a game store, they also provide game news, practical guides, game tools, and videos. 3839 has both a mobile platform and web version. 3839 is a games-only store, and does not take a cut from ad-supported games, as the store does not have its own ads SDK (developers must supply their own).",
    img: <StaticImage src="../../images/game_stores/3839.jpeg" {...imgProps} />,
    url: "https://www.3839.com/",
  },
  {
    title: "2345",
    description:
      "Shanghai 2345 Network Technology Co., Ltd. was established in September 2005 in Shanghai. It is a leading domestic Internet company integrating Internet operations and software development. It claims a “mobile internet + artificial intelligence” strategy. 2345 is a games-only store and does not allow pay-to-download games on its platform.",
    img: <StaticImage src="../../images/game_stores/2345.png" {...imgProps} />,
    url: "http://zhushou.2345.com/",
  },
  {
    title: "Steam China (蒸汽平台)",
    description: `At a press conference on August 21, 2019, "Steam China" was officially introduced as China’s Steam platform. It is almost completely independent of Steam Global. Steam China went online with only 40 games initially, and Chinese users have criticized its lack of availability of games compared with Steam International.`,
    img: (
      <StaticImage src="../../images/game_stores/steam.jpeg" {...imgProps} />
    ),
    url: "https://store.steampowered.com/",
  },
];
